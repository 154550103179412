.ant-menu-item-group-title {
  color: #949ea6;
  font-size: 11px;
  padding: 0;
  margin-bottom: 8px;
}

.ds-menu-item {
  padding: 0 !important;
}
