.forecast-left-sidebar--list {
  list-style-type: none;
}

.forecast-left-sidebar--bordered .forecast-left-sidebar--bordered {
  border-left: 1px solid #cecece;
}

.forecast-left-sidebar--root {
  padding-inline-start: 0;
}

ul {
  padding-inline-start: 15px;
}

.forecast-left-sidebar--droptarget {
  background-color: #e8f0fe;
}
