.rich-text-menu > * {
  display: inline-block;
  overflow-y: scroll;
}

.rich-text-menu > * + * {
  margin-left: 15px;
}

.editable-rich-text p {
  color: #888;
  line-height: 1rem;
  font-size: 14px;
}

.editable-rich-text h1,
.editable-rich-text h2 {
  line-height: 2.5rem;
}

.editable-rich-text h1 {
  font-size: 20px;
}

.editable-rich-text h2 {
  font-size: 16px;
}

.editable-rich-text p > * strong > * {
  font-weight: bolder;
  color: #000;
}
