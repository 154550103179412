input {
  border: 1px solid #ccc;
  padding: 0.5rem;
  width: 300px;
}

.no-suggestions {
  color: #ccc;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #ccc;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
  bakcground-color: white;
  position: absolute;
  z-index: 999999 !important;
}

.suggestions li {
  padding: 0.5rem;
  background-color: white;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #dcebf7;
  color: #1f78a2;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #ccc;
}

.token {
  background-color: #dcebf7;
  border-radius: 0.25rem;
  padding: 0.25rem;
  color: #1f78a2;
}

.text {
  display: inline-block;
  margin: 0;
}

[contenteditable="true"]:empty:not(:focus):before {
  content: attr(data-ph);
  color: grey;
  font-style: italic;
}
