.react-grid-item.react-grid-placeholder {
  background: #fff;
  opacity: 0.5;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}