.react-resizable-handle {
  background-image: none;
  padding: 0;
  background-color: #1f5e95;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s,
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne,
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw,
.react-grid-item > .react-resizable-handle.react-resizable-handle-se,
.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  transform: none;
  margin: 0;
  border-radius: 4px;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  width: 32px;
  height: 4px;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-e,
.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  width: 4px;
  height: 32px;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  width: 16px;
  height: 16px;
  border-top: 4px solid #1f5e95;
  border-right: 4px solid #1f5e95;
  background-color: transparent;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  width: 16px;
  height: 16px;
  border-top: 4px solid #1f5e95;
  border-left: 4px solid #1f5e95;
  background-color: transparent;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  width: 16px;
  height: 16px;
  border-bottom: 4px solid #1f5e95;
  border-right: 4px solid #1f5e95;
  background-color: transparent;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  width: 16px;
  height: 16px;
  border-bottom: 4px solid #1f5e95;
  border-left: 4px solid #1f5e95;
  background-color: transparent;
}

.react-grid-item > .react-resizable-handle::after {
  border: none;
  content: none;
}

.react-grid-item > .react-resizable-handle::after {
  border: none;
  content: none;
}

.react-grid-item > .react-resizable-handle {
  visibility: hidden;
}

.react-grid-item:hover > .react-resizable-handle {
  visibility: visible;
}
